import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

type ContentLoadingProps = Pick<CircularProgressProps, 'size'>

function ContentLoading({ size = 40 }: ContentLoadingProps): JSX.Element {
  return (
    <Grid container direction="column" alignItems="center" sx={{ flexGrow: 1 }}>
      <CircularProgress data-testid="circularProgress" color="secondary" size={size} />
    </Grid>
  )
}

export default ContentLoading
