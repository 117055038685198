import { SsidChart } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { DeepcellPrimarySelect } from 'components/shared'
import ContentLoading from 'components/shared/ContentLoading'
import DeepcellPopper from 'components/shared/DeepcellPopper'
import DeepcellPrimaryIconButton from 'components/shared/DeepcellPrimaryIconButton'
import DeepcellToggleButton from 'components/shared/DeepcellToggleButton'
import useFlagCondition from 'components/shared/useFlagCondition'
import React, { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { FullscreenPanelType } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DifferentialFeaturesComparison, TopFeature } from 'redux/slices/types'
import {
  DifferentialFeaturesGetTaskInput,
  getDifferentialFeaturesResult,
  TaskStatus,
} from 'utils/api'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import DistributionComparisonPlot from './DistributionComparisonPlot'
import { getComparisonName, getFeatureName } from './resultItemUtils'

const POLL_WAIT = 1000

export const RESULT_COUNT_OPTIONS = [5, 10, 25, 50]

const StyledTableCell = styled(TableCell)({
  padding: '8px',
  borderBottom: '1px solid black',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
})

const StyledContainer = styled('div')({
  border: '1px solid #C4C6FC',
  paddingTop: '10px',
  paddingBottom: '10px',
  borderRadius: '4px',
  marginBottom: '8px',
})

const SelectContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '30px',
})

interface ResultItemProps {
  comparison?: DifferentialFeaturesComparison
}

const ResultItem: React.FC<ResultItemProps> = ({ comparison }: ResultItemProps): JSX.Element => {
  const {
    visiblePinnedCells: pinnedCells, // used to get up to date names
    cellVisualizations: { fullscreenPanel, selectedDifferentialFeaturesTaskId, differentialFeaturesTopFeatureCount  },
    deleteDifferentialFeaturesComparisonByTaskId,
    updateDifferentialFeaturesComparison,
    setFullscreenPanel,
    setSelectedDifferentialFeaturesTaskId,
    setShowCompare,
    setResultCount
  } = useCellVisualizationsSlice()

  const { sessionId, versionId } = useCellVisualizationUrlParams()

  const eventsManager=useEventManager()

  const MISSING_TASK_ID = -1
  const taskId = comparison?.taskId !== undefined ? comparison.taskId : MISSING_TASK_ID

  // Whether this task is currently selected to show details in fullscreen view
  const hasFullscreenPanel = fullscreenPanel === FullscreenPanelType.DIFFERENTIAL_FEATURES
  const showTaskDetails = taskId === selectedDifferentialFeaturesTaskId && hasFullscreenPanel

  // Check if this task is still loading
  const isLoading = comparison?.status !== TaskStatus.READY || comparison?.topFeatures === undefined

  // Whether to show the buttons to show individual plots in a popover
  const enableIndividualPlots = useFlagCondition(
    'cellVisualizationDifferentialFeaturesIndividualPlotsEnabled'
  )

  // Whether to show the popover for just this feature
  const [popoverFeature, setPopoverFeature] = React.useState<keyof CellInfo | null>(null)
  const [popoverEl, setPopoverEl] = React.useState<null | HTMLElement>(null)

  // Number of results to show
  const topFeaturesToShow = useMemo(
    () => comparison?.topFeatures?.slice(0, differentialFeaturesTopFeatureCount ),
    [comparison?.topFeatures, differentialFeaturesTopFeatureCount ]
  )

  // Get the top feature results, if the results are not populated yet
  const getTaskInput: DifferentialFeaturesGetTaskInput = {
    sessionId,
    versionId,
    taskId,
  }
  const queryKey: [string, DifferentialFeaturesGetTaskInput] = [
    'getDifferentialFeaturesResult',
    getTaskInput,
  ]

  const queryClient = useQueryClient()

  // Poll until we have a result!
  useQuery(queryKey, getDifferentialFeaturesResult, {
    // Only make this query if we don't already have results
    enabled: comparison?.status === TaskStatus.PENDING && taskId !== MISSING_TASK_ID,

    // Don't retry if there's an error -- it's probably not transient
    retry: false,

    // Refetch every POLL_WAIT milliseconds
    refetchInterval: POLL_WAIT,

    // Don't keep polling if there's an error.  It's probably not transient
    onError: async () => {
      await queryClient.cancelQueries(queryKey)
      updateDifferentialFeaturesComparison({
        taskId,
        status: TaskStatus.FAILED,
      })
    },

    onSuccess: async (response) => {
      // If we get a successful response
      const { data } = response
      if (
        data !== undefined &&
        data.status !== TaskStatus.PENDING &&
        data.result?.top_features !== undefined
      ) {
        // Stop polling if we have a result (FAILED or READY)
        await queryClient.cancelQueries(queryKey)

        if (data.status === TaskStatus.READY) {
          updateDifferentialFeaturesComparison({
            taskId: data.task_id,
            status: data.status,
            topFeatures: data.result.top_features,
          })
        } else {
          // Handle failure case better
        }
      }
    },
  })

  const showDetails = (event: React.MouseEvent) => {
    event?.stopPropagation()
    if (showTaskDetails) {
      setFullscreenPanel(FullscreenPanelType.NONE)
      setSelectedDifferentialFeaturesTaskId(undefined)
    } else {
      // Hide the compare tab to make room, if it's open
      setShowCompare(false)
      setSelectedDifferentialFeaturesTaskId(taskId)
      setFullscreenPanel(FullscreenPanelType.DIFFERENTIAL_FEATURES)
    }
  }

  const handleDeleteClick = (event: React.MouseEvent) => {
    event?.stopPropagation()
    if (taskId !== undefined) {
      deleteDifferentialFeaturesComparisonByTaskId({ taskId })
    }
  }

  const onClosePopover = () => {
    setPopoverFeature(null)
    setPopoverEl(null)
  }

  const onShowPopover = (event: React.MouseEvent, feature: keyof CellInfo) => {
    // If the user clicks on the same button while already showing the feature, hide it
    if (popoverFeature === feature) {
      onClosePopover()
      return
    }
    event?.stopPropagation()
    setPopoverFeature(feature)
    // Find the parent div that contains the full contents of the cell
    setPopoverEl(event.currentTarget.closest('td'))
  }

  return (
    <StyledContainer data-testid="differential-features-result-item">
      <Accordion sx={{ boxShadow: 'none' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ marginBottom: '-15px', marginTop: '-10px' }}
        >
          <SelectContainer>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography sx={{ fontSize: '16px', flexGrow: 1 }}>
                {getComparisonName(comparison, pinnedCells)}
              </Typography>
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ContentLoading size={20} />
                </Box>
              )}
            </Stack>
          </SelectContainer>
        </AccordionSummary>

        {!isLoading && (
          <AccordionDetails>
            <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '8px' }}>
              <Box sx={{ width: '100px' }}>
                <DeepcellPrimarySelect
                  value={differentialFeaturesTopFeatureCount }
                  onChange={(e) => {
                    setResultCount(e.target.value as number)
                    eventsManager.sendResultCount(e.target.value as number)
                  }}
                  renderValue={(value) => `Top ${value}`}
                  items={RESULT_COUNT_OPTIONS}
                />
              </Box>
              <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
                <DeepcellToggleButton
                  sx={{ border: '1px solid rgba(95, 85, 209, 0.5)' }}
                  color="primary"
                  value="check"
                  selected={showTaskDetails}
                  onClick={(e) => {
                    showDetails(e)
                    eventsManager.sendMultipleTopFeatures()
                  }}
                >
                  <SsidChart />
                </DeepcellToggleButton>
                <DeepcellPrimaryIconButton
                  outlined
                  sx={{ paddingLeft: '12px', paddingRight: '12px' /* makes it square */ }}
                  icon={<DeleteIcon />}
                  aria-label="delete"
                  onClick={(e) => {
                    handleDeleteClick(e)
                    eventsManager.sendDeleteDifferentialFeature()
                  }}
                />
              </Stack>
            </Stack>
            <TableContainer sx={{ boxShadow: 'none', border: '1px solid black' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: '#DDF9E8' }}>
                    <StyledTableCell sx={{ borderRight: '1px solid black', width: '70%' }}>
                      Feature
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '30%' }}>Divergence Score</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontWeight: '400', fontSize: '12px', lineHeight: '16px' }}>
                  {comparison !== undefined &&
                    topFeaturesToShow?.map((data: TopFeature,index:number) => {
                      return (
                        <TableRow data-testid="result-row" key={`${taskId}-${data.feature}`}>
                          <StyledTableCell sx={{ borderRight: '1px solid black', width: '70%' }}>
                            <Stack direction="row" sx={{ alignItems: 'center' }}>
                              <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
                                {getFeatureName(data.feature)}
                              </Box>
                              {enableIndividualPlots && (
                                <DeepcellPrimaryIconButton
                                  // Mess with the margin to keep the button size big enough to interact with
                                  // But the row size of the datatable narrow to fit more data on screen
                                  sx={{
                                    mt: '-8px',
                                    mb: '-8px',
                                    pl: '4px',
                                    pr: '4px',
                                    borderColor: 'transparent',
                                  }}
                                  contained={false}
                                  variant="text"
                                  icon={<SsidChart />}
                                  onClick={(e) => {
                                    onShowPopover(e, data.feature as keyof CellInfo)
                                    eventsManager.sendSingleTopFeatureEvent(
                                      getFeatureName(data.feature),
                                      index
                                    )
                                  }}
                                />
                              )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '30%', textAlign: 'right' }}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ alignItems: 'center' }}
                            >
                              <LinearProgress
                                variant="determinate"
                                value={data.score * 100}
                                sx={{ height: '20px', width: '70%' }}
                              />
                              <Box>{data.score.toFixed(2)}</Box>
                            </Stack>
                          </StyledTableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        )}
      </Accordion>
      {comparison !== undefined && popoverFeature !== null && (
        <DeepcellPopper
          sx={{ zIndex: 2000 }} // Needs to be more than what's in the CellSelectionDrawer
          headerTitle={getFeatureName(popoverFeature)}
          open={popoverFeature !== null}
          anchorEl={popoverEl}
          placement={hasFullscreenPanel ? 'right-start' : 'left-start'}
          onClose={onClosePopover}
        >
          <DistributionComparisonPlot
            comparison={comparison}
            feature={popoverFeature}
            width={500}
            height={300}
            showTitle={false}
          />
        </DeepcellPopper>
      )}
    </StyledContainer>
  )
}

export default ResultItem
