import { Stack, styled } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import SectionCard from 'components/shared/SectionCard'
import { outputColumnDefs, RowDataType } from 'components/runs/BasicInfoTableConfig'
import { ColDef } from 'ag-grid-community'
import { useQueryClient } from 'react-query'
import PrimaryButton from 'components/runs/PrimaryButton'
import { getSession, SessionPostData } from 'utils/api'
import { formatTimestampToHumanReadable, INSTRUMENT_REPORTED_TIMEFORMAT } from 'components/shared/date-utils'
import AgGridSessionDetailsTable from './AgGridSessionDetailsTable'

interface Props {
  setShowDetailsPanel: (value: boolean) => void
  selectedSession: number
  setSelectedSession: (value: number) => void
  handleOpenSession: (value: number) => void
}

const ActionBarContainer = styled(Stack)({
  borderRadius: '4px',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
  padding: '8px 6px',
  height: '48px',
})

function SessionDetailsPanel(props: Props): JSX.Element {
  const { setShowDetailsPanel, selectedSession,setSelectedSession,handleOpenSession } = props
  const queryClient = useQueryClient()
  const [session, setSession] = useState<SessionPostData | undefined>()
  

  const gridOptions = {
    defaultCsvExportParams: {
      columnKeys: ['label', 'values'],
    },
  }

  const select = () => () => {}

  const columnDefs: ColDef[] = outputColumnDefs(select)
  const rowData: RowDataType[] = []
  const getRows = useCallback(async () => {    
    if (selectedSession) {
      try {
        const results = await queryClient.fetchQuery(['getSessions'], () =>
          getSession(selectedSession)
        )
        const fetchedData = results.data.data
        setSession(fetchedData)
      } catch (err) {
        console.error(err)
      }
    }
      
    
  }, [selectedSession,queryClient])

  useEffect(() => {
    getRows()
  }, [getRows, selectedSession])
   

  if (session&&session.version_config?.name) {
    rowData.push({
      label: 'Session Name',
      values: session.version_config.name || '',
    })
  }
  if (session&&session.version_config?.projectCode) {
    rowData.push({
      label: 'Project Code',
      values: session.version_config.projectCode || '',
    })
  }
  if (session&&session?.runs&&session?.runs) {
    rowData.push({
      label: 'Sample Id',
      values: session.runs.map((run)=>run.sample_id) || '',
    })
  }
  if (session&&session?.runs&&session?.runs) {
    rowData.push({
      label: 'Sample Type',
      values: session.runs.map((run)=>run.sample_type) || '',
    })
  }
  if (session&&session.updated_at) {
    rowData.push({
      label: 'Last Updated At',
      values: formatTimestampToHumanReadable(session.updated_at,INSTRUMENT_REPORTED_TIMEFORMAT),
    })
  }
  if (session&&session.author_email) {
    rowData.push({
      label: 'Creator',
      values: session.author_email || '',
    })
  }
  if (session&&session.runs) {
    rowData.push({
      label: 'Run Ids',
      values: session.runs.map((run)=>run.run_id)  || '',
    })
  }
  if (session&&session?.cell_group_classifier_model_id) {
    rowData.push({
      label: 'Classifier Name',
      values: session.cell_group_classifier_model_id  || '',
    })
  }
  
  return (
    <Stack gap="15px">
      <SectionCard
        paddingCSS="0px"
        title="Session Details"
        closeAction={() => {setShowDetailsPanel(false);setSelectedSession(0)}}
      >
        <ActionBarContainer>
          <PrimaryButton sx={{ width: '123px' }} onClick={()=>handleOpenSession(Number(selectedSession))}>
            Open Session
          </PrimaryButton>
        </ActionBarContainer>
        <AgGridSessionDetailsTable
          testId="session-details-grid"
          customClass="context-menu-ag-grid"
          headerHeight={0}
          rowData={rowData}
          gridOptions={gridOptions}
          rowGap={8}
          columnDefs={columnDefs}
        />
      </SectionCard>
    </Stack>
  )
}

export default React.memo(SessionDetailsPanel)
