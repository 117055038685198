import { Close } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { CellVisualizationsSectionHeader } from 'components/cell-visualizations/shared'
import { DeepcellPrimaryButton } from 'components/shared'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import _ from 'lodash'
import { useState } from 'react'
import { DifferentialFeaturesComparison, PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

interface MergePinnedProps {
  onCloseClick: () => void
  defaultSelected?: PinnedMorphotype
  differentialFeaturesComparisons?:DifferentialFeaturesComparison[]
  deleteComparisonIfIncludesMorphotype?:(comparison:DifferentialFeaturesComparison,morphotypeId:number)=>void
}

export const MergePinned = ({ onCloseClick, defaultSelected, differentialFeaturesComparisons, deleteComparisonIfIncludesMorphotype }: MergePinnedProps): JSX.Element => {
  const {
    setPinnedCells,
    setMergedPinnedCells,
    setPinnedGroupHighlighted,
    cellVisualizations: { pinnedCells, mergedPinnedCells },
  } = useCellVisualizationsSlice()

  const [pinnedMorphotypesToMerge, setPinnedMorphotypesToMerge] = useState<PinnedMorphotype[]>(
    defaultSelected ? [defaultSelected] : []
  )
  const [openConfirmMerge, setOpenConfirmMerge] = useState(false)
  const eventsManager = useEventsManager()
  let  differentialFeatureCount = 0

  const close = () => {
    if (pinnedCells) {
      pinnedCells.forEach((pc) =>
        setPinnedGroupHighlighted({
          pinnedGroupId: pc.id,
          isHighlighted: false,
        })
      )
    }
    onCloseClick()
  }

  const handleCheckboxClicked =
    (pinnedMorphotype: PinnedMorphotype) => (__: unknown, checked: boolean) => {
      if (checked) {
        setPinnedGroupHighlighted({ pinnedGroupId: pinnedMorphotype.id })
        setPinnedMorphotypesToMerge([...pinnedMorphotypesToMerge, pinnedMorphotype])
      } else {
        setPinnedGroupHighlighted({
          pinnedGroupId: pinnedMorphotype.id,
          isHighlighted: false,
        })
        setPinnedMorphotypesToMerge([
          ...pinnedMorphotypesToMerge.filter((p) => p.id !== pinnedMorphotype.id),
        ])
      }
    }

    const updateDifferentialFeatureComparison = () => {
      if (
        pinnedMorphotypesToMerge &&
        differentialFeaturesComparisons &&
        deleteComparisonIfIncludesMorphotype
      ) {
        pinnedMorphotypesToMerge.forEach((obj) => {
          differentialFeaturesComparisons.forEach((comparison) => {
            deleteComparisonIfIncludesMorphotype(comparison, obj.id)
          })
        })
      }
    }
  

  const handleMerge = () => {
    const idsToMerge = pinnedMorphotypesToMerge.map((p) => p.id)
    const name = pinnedMorphotypesToMerge.map((p) => p.name).join(' & ')

    const newPinnedMorphotypes =
      pinnedCells?.map((pinnedMorphotype) => {
        if (!idsToMerge.includes(pinnedMorphotype.id)) return pinnedMorphotype

        // give merged cells the same id and name
        return { ...pinnedMorphotype, id: idsToMerge[0], name }
      }) ?? []

    setPinnedCells({ pinnedCells: newPinnedMorphotypes, eventsManager })

    idsToMerge.forEach((pinnedGroupId) => {
      setPinnedGroupHighlighted({
        pinnedGroupId,
        isHighlighted: false,
      })
    })

    const mergedPoints = _.shuffle(
      _.uniqBy(
        newPinnedMorphotypes
          ?.filter((x) => idsToMerge.includes(x.id))
          .flatMap((x) => x.cells.points ?? []),
        (p) => `${p.x}${p.y}`
      )
    )

    setMergedPinnedCells({
      pinnedCells: [
        ...(mergedPinnedCells?.filter((x) => {
          const differsFromNewGroup = x.id !== idsToMerge[0]
          const idExists = newPinnedMorphotypes.map((y) => y.id).includes(x.id)

          return differsFromNewGroup && idExists
        }) ?? []),
        { id: idsToMerge[0], cells: { points: mergedPoints } },
      ],
      eventsManager,
    })
    updateDifferentialFeatureComparison()
    setPinnedMorphotypesToMerge([])
    setOpenConfirmMerge(false)
    close()
  }

 
  // Only return the first of a pinnedMorphotype if there are multiples
  const mergedPinnedMorphotypes = pinnedCells?.filter(
    (pcg, i) => pinnedCells.findIndex((x) => x.id === pcg.id) === i
  )

  if (pinnedMorphotypesToMerge && differentialFeaturesComparisons) {
    differentialFeatureCount = pinnedMorphotypesToMerge.reduce((totalCount, obj) => {
      const matchingComparisonsCount = differentialFeaturesComparisons?.filter(
        (comparison) =>
          comparison.morphotypeIds1.includes(obj.id) || comparison.morphotypeIds2.includes(obj.id)
      ).length
      return totalCount + matchingComparisonsCount
    }, 0)
  }
 
  return (
    <Stack spacing={2}>
      <Stack mx={1} direction="row" alignItems="center" justifyContent="space-between">
        <CellVisualizationsSectionHeader>Merge Pinned Images</CellVisualizationsSectionHeader>
        <DeepcellPrimaryButton
          small
          onClick={() => setOpenConfirmMerge(true)}
          disabled={pinnedMorphotypesToMerge.length < 2}
        >
          Merge
        </DeepcellPrimaryButton>
        <IconButton data-testid="mergedPinnedCloseButton" onClick={close}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex' }} p={1}>
        <Stack width="100%">
          {mergedPinnedMorphotypes?.map((pinnedMorphotype) => {
            return (
              <FormGroup key={pinnedMorphotype.id}>
                <Stack key={pinnedMorphotype.id} direction="row" justifyContent="space-between">
                  <FormControlLabel
                    checked={pinnedMorphotypesToMerge
                      .map((x) => x.id)
                      .includes(pinnedMorphotype.id)}
                    control={
                      <Checkbox
                        data-testid="pinnedImagesCheckbox"
                        onChange={handleCheckboxClicked(pinnedMorphotype)}
                      />
                    }
                    label={pinnedMorphotype.name}
                  />
                </Stack>
              </FormGroup>
            )
          })}
        </Stack>
      </Box>
      <DeepcellDialog
        data-testid={`mergedPinnedDialog_${openConfirmMerge ? 'true' : 'false'}`}
        open={openConfirmMerge}
        handleConfirm={handleMerge}
        handleCancel={() => setOpenConfirmMerge(false)}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Merge"
      >
        {differentialFeatureCount > 0 ? (
          <DialogContentText>
            <Typography mb={2}>
              You are about to merge the following morphotypes.
              {`This will invalidate and remove ${differentialFeatureCount} associated differential feature comparisons`}
            </Typography>
            <Stack>
              {pinnedMorphotypesToMerge.map((p) => (
                <Typography key={p.id}>{`• ${p.name}`}</Typography>
              ))}
            </Stack>
          </DialogContentText>
        ) : (
          <DialogContentText>
            <Typography mb={2}>
              Are you sure you want to merge the following morphotypes?
            </Typography>
            <Stack>
              {pinnedMorphotypesToMerge.map((p) => (
                <Typography key={p.id}>{`• ${p.name}`}</Typography>
              ))}
            </Stack>
          </DialogContentText>
        )}
      </DeepcellDialog>
    </Stack>
  )
}

export default MergePinned
